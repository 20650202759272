import { Controller } from "@hotwired/stimulus"
import Highcharts from 'highcharts';


export default class extends Controller {

  static values = {
    inputs: Object
  }

  connect() {

    const chartData = JSON.parse(this.element.getAttribute('data-show-stock-revenue-page-inputs-value'));

    Highcharts.chart('stockShowRevenueChartContainer', {
      chart: {
        type: 'area',
        zooming: {
          type: 'x'
        },
        panning: true,
        panKey: 'shift',
        scrollablePlotArea: {
          minWidth: 600
        }
      },
    
      title: {
        text: '',
        align: 'left'
      },
    
      credits: {
        enabled: false
      },
    
      // Removed annotations to simplify the chart
      xAxis: {
        labels: {
          format: '{value}'
        },
        minRange: 1,
        title: {
          text: 'Years'
        },
      },
    
      yAxis: {
        startOnTick: true,
        endOnTick: false,
        maxPadding: 0.35,
        title: {
          text: 'Revenue'
        },
        labels: {
          format: `{value} ${chartData.reported_currency}`
        },
      },
    
      tooltip: {
        headerFormat: 'Year: {point.x}<br>', // Removed decimal places from the year
        pointFormat: `Revenue: {point.y} ${chartData.reported_currency}`,
        shared: true
      },
    
      legend: {
        enabled: false
      },
    
      series: [{
        data: chartData.annual_data, // Assuming `chartData.annual_data` is an array of [year, revenue]
        lineColor: Highcharts.getOptions().colors[1],
        color: Highcharts.getOptions().colors[2],
        fillOpacity: 0.5,
        name: 'Revenue',
        marker: {
          enabled: false
        },
        threshold: null
      }],
    
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500 // Adjust for smaller screens
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            },
            yAxis: {
              labels: {
                format: `{value} ${chartData.reported_currency}`
              }
            },
            tooltip: {
              headerFormat: 'Year: {point.x}<br>',
              pointFormat: `Revenue: {point.y} ${chartData.reported_currency}`
            }
          }
        }]
      }
    });
  }
}